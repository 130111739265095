.faq {
  width: 100%;
  padding: 40px;
  color: #000;

  &__title {
    margin: 0;
  }

  &__question {
    width: 100%;
    border-bottom: 1px solid #9e3ffd;
    dd {
      p {
        line-height: 1.6;
        span {
          margin-top: 10px;
          svg:hover {
            fill: #9e3ffd;
          }
        }
      }
    }
  }

  &__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    text-align: left;
    line-height: 1.5;
    padding: 34px 0;
    font-size: 18px;
    font-family: "Avenir";
    &::after {
      content: url(../images/icon-arrow-down.svg);
      margin-right: 17px;
      transform: rotate(90deg);
    }
  }

  &__desc {
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
    font-size: 18px;
    font-family: "Avenir";
  }
  p.sub_title {
    font-size: 16px;
    font-weight: bold;
    color: #9e3ffd;
    font-family: "Avenir-bold";
  }
  .faq__header {
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    width: 55%;
    margin-left: auto;
    justify-content: space-between;
    .search-bar {
      display: flex;
      align-items: center;
      input {
        opacity: 0;
        height: 50px;
        border: 0;
        border-bottom: 1px solid #9e3ffd;
        outline: none;
        transition: 0.3s;
        padding-left: 15px;
      }
      span {
        height: fit-content;
        svg {
          height: fit-content;
        }
      }
    }
  }
}
