@import "config";

@font-face {
  font-family: "Avenir";
  src: url("../../assets/avenir-font/Avenir-Light.woff2");
}
@font-face {
  font-family: "Avenir-bold";
  src: url("../../assets/avenir-font/Avenir-Bild.woff2");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap");

body {
  background-color: #e7e7eb;
  color: #fff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  color: $very-dark-desaturated-blue;
  font-size: 32px;
  line-height: 32px;
}

button {
  color: $very-dark-grayish-blue;
  font-size: 14px;
  line-height: 14px;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
/* Remove the focus indicator on mouse-focus for browsers that do support :focus-visible */
button:focus:not(:focus-visible) {
  outline: 0;
}

button:focus-visible {
  /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
  outline: 2px dashed $soft-red;
  outline-offset: 0px;
}

#root {
  width: 100%;
  height: 100vh;
}

.app {
  height: 100%;
}

.main__container {
  width: 100%;
  height: 100%;
  max-width: 1350px;
  margin: 0 auto;
  // padding: 0 20px;

  .analysis__transactions {
    height: 100%;
    // margin-bottom: 50px;
    // margin-top: 50px;
    display: grid;
    // grid-template-columns: 4fr 6fr;
    column-gap: 40px;
    row-gap: 50px;

    .tradingview-widget-container {
      height: 100% !important;
      max-height: 100%;
      min-height: 532px !important;
      #data {
        height: 100% !important;
        max-height: 100%;
        min-height: 532px !important;
      }
    }

    .transaction {
      background-color: #e7e7eb;
      color: rgb(22, 22, 63);
      width: 100%;
      padding: 20px;
      border-radius: 8px;
      .connect__wallet {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin-top: 10px;
        }
        button {
          border-radius: 0;
          outline: 0;
          font-size: 15px;
          color: #fff;
          border: 1px solid transparent;
          cursor: pointer;
          transition: 0.3s;
          width: 200px;
          height: 40px;
          margin-top: 30px;
        }
        .deposit {
          background-color: #5095fd;
        }
        button:hover {
          background-color: #fff;
          color: #1e222d;
          border: 1px solid #1e222d;
        }
      }
      .transaction__header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 15px;
        height: 40px;
        button {
          border-radius: 0;
          outline: 0;
          font-size: 15px;
          color: #fff;
          border: 1px solid transparent;
          cursor: pointer;
          transition: 0.3s;
          font-family: "Avenir";
        }
        .deposit {
          background-color: #5095fd;
        }
        .panic {
          background-color: #8b0000;
        }
        .request {
          background-color: #90a749;
        }
        button:hover {
          background-color: #fff;
          color: #1e222d;
          border: 1px solid #1e222d;
        }
      }
      .info-line {
        display: flex;
        justify-content: space-between;
      }
      .timer {
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: "Avenir" !important;
      }
      .notie {
        text-align: right;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: "Avenir" !important;
      }
      .transaction__body {
        // margin-top: 15px;
        text-align: center;
        h2 {
          width: 100%;
          max-width: 415px;
          margin: 0 auto;
          font-size: 26px;
          color: #16163f;
          line-height: 36px;
          font-weight: bold;
          padding-bottom: 10px;
          font-weight: 600;
          span {
            color: #90a749;
            font-weight: bold;
          }
        }
        .tarde__list {
          border-top: 3px solid #1e222d;
          padding-top: 10px;
          padding-bottom: 30px;
          border-bottom: 1px solid #16163f;
          h3 {
            font-size: 27px;
            font-weight: 200;
            color: #16163f;
            margin-bottom: 10px;
          }
          .all_cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            column-gap: 15px;
            row-gap: 15px;
            .card {
              background-color: #fff;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              padding: 20px 10px;
              h4 {
                font-weight: 500;
                padding-bottom: 20px;
                border-bottom: 1px solid #16163f;
                font-weight: 300;
                font-size: 1rem;
                height: 50px;
              }
              p {
                margin-top: 20px;
                margin-bottom: 0px;
                font-weight: 300;
              }
              .blue__color {
                color: #5095fd;
              }
              .color {
                color: #90a749;
              }
            }
          }
        }
      }
      .transaction__footer {
        text-align: center;
        h4 {
          margin-top: 15px;
          font-size: 27px;
          font-weight: lighter;
          color: #16163f;
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
  .chart__profile {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 40px;
    row-gap: 50px;
    .chart_view {
      width: 100%;
    }
    .profile_view {
      .tradingview-widget-copyright {
        display: none;
      }
    }
  }
  .market__overview {
    width: 100%;
    background-color: #1e222d;
    padding: 15px;
    border-radius: 16px;
    h5 {
      color: rgb(231, 231, 235);
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 15px;
    }
  }
}

.font-weight-bold {
  font-weight: 700;
  color: #9e3ffd;
  padding-bottom: 20px !important;
}

@import "component";
@import "faq";
@import "animation";
@import "responsive";
