//Primary Text colors
$very-dark-desaturated-blue: hsl(238, 29%, 16%);
$soft-red: hsl(14, 88%, 65%);

// Background gradient:
$soft-violet: hsl(273, 75%, 66%);
$soft-blue: hsl(240, 73%, 65%);

//Neutral Text colors
$very-dark-grayish-blue: hsl(237, 12%, 33%);
$dark-grayish-blue: hsl(240, 6%, 50%);

//Dividers
$light-grayish-blue: hsl(240, 5%, 91%);
