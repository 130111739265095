@media screen and (max-width: 1200px) {
  .main__container .chart__profile {
    grid-template-columns: 3fr 1fr;
  }
}
@media screen and (max-width: 896px) {
  .main__container .chart__profile {
    grid-template-columns: 1fr;
  }
  .main__container .analysis__transactions .tradingview-widget-container,
  .main__container .analysis__transactions .transaction {
    width: 100% !important;
  }
  .main__container .chart__profile .profile_view {
    width: 100%;
    .COMPANY_PROFILE {
      height: 540px !important;
      .tradingview-widget-container {
        height: 540px !important;
        iframe {
          height: 540px !important;
        }
      }
    }
  }
  .faq .faq__header {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .main__container .analysis__transactions {
    grid-template-columns: 1fr;
  }
}
